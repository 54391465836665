.toggle{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 1rem;
    position: relative;
    padding: 2px;
    border: 3px solid var(--orange);

}
.toggle>*{
    width: 1rem;
    height: 1rem;
    color: var(--orange)
    ;
}
.t-button{
    border-radius: 100%;
    background-color: var(--orange)
    ;
    position: absolute;
}