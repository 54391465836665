.services {
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 30rem ;
    height: 30rem;
    margin-bottom: 8rem;
    margin-top: 9rem;
    margin-bottom: 13rem;
    /* scroll view */
    padding-top: 3rem;
    gap: 20rem;
    
  }
  /* left side */
  .awesome {
    display: flex;
    flex-direction: column;
    position: relative;
    /* gap: 20px; */
  }
  .awesome > :nth-child(1) {
    color: var(--black);
    font-size: 2.5rem;
    font-weight: bold;
  }
  .awesome > :nth-child(2) {
    color: var(--orange);
    font-size: 2.5rem;
    font-weight: bold;
  }
  .awesome > :nth-child(3) {
    color: var(--gray);
    font-size: 14px;
    margin-top: 1rem;
  }
  .s-button {
    width: 9rem;
    height: 2rem;
    margin-top: 1rem;
  }
  .motion1{
    left: 14rem;
  }
  .motion2{
    top: 12rem;
    left: -4rem;
  }
  .motion3{
    top: 19rem;
    left:12rem;

  }

  @media screen  and (max-width:480px){
    .services{
      margin-top: 0;
      flex-direction: column;
      gap: 5rem;
      height: 66rem;
      padding: 0;
    }
    .cards{
      display: flex;
      flex-direction: column;
      gap: 17rem;
     

    }
    .cards>*{
      position: static;
    }
    .motion1{
     left: -1rem;
     

    }
    .motion2{
      top: 14rem;
      left: -2.5rem;
    }
    .motion3{
      top: 28rem;
      left: -0.5rem;
    }



  }